<template lang="pug">
BasePane
  Dropdown#countdown-type(
    :items="countdownTypes"
    v-model="countdownType"
    :label="$t('countdownType')"
    layout="col"
  )
  Heading(label :open="timeUnitLabel") {{ $t('timeUnitLabels') }}
    template(#prependEnding)
      OmSwitch#time-unit-label(v-model="timeUnitLabel")
    template(#expandableContent)
      OmInput#days(:label="$t('countdownLabels.days')" v-model="days" small)
      OmInput#hours(:label="$t('countdownLabels.hours')" v-model="hours" small)
      OmInput#minutes(:label="$t('countdownLabels.minutes')" v-model="minutes" small)
      OmInput#seconds(:label="$t('countdownLabels.seconds')" v-model="seconds" small)
  template(v-if="isFixedTime")
    .fixed-time-container.mt-3
      .col.px-0
        Dropdown#days-value.mb-2(:items="daysOptions" :label="$t('days')" v-model="dayValue")
        Dropdown#hours-value.mt-2(:items="hoursOptions" :label="$t('hours')" v-model="hourValue")
      .col.px-0
        Dropdown#minutes-value.mb-2(
          :items="minutesOptions"
          :label="$t('minutes')"
          v-model="minuteValue"
        )
        Dropdown#seconds-value.mt-2(
          :items="secondsOptions"
          :label="$t('seconds')"
          v-model="secondValue"
        )
  template(v-else)
    date-time(property="data.countdown.endDate.date" small)
  hr.sidebar-spacing-y
  Font(
    fontFamilyProperty="subElements.shared.$device.fontFamily"
    hideStyle
    hideAlign
    :needMoreOrLess="false"
  )
    template(#colors)
      OmColorInput(
        :label="$t('numberColor')"
        property="subElements.number.desktop.color"
        typeOfComponent="countdown"
        layout="col"
        dsAllowed
      )
      OmColorInput(
        :label="$t('unitColor')"
        property="subElements.label.desktop.color"
        typeOfComponent="countdown"
        layout="col"
        dsAllowed
      )
    template(#afterColors)
      RangeInput(
        :label="$t('fontSize')"
        :min="8"
        :max="90"
        :step="1"
        v-model="fontSize"
        editMobile
      )
  hr.sidebar-spacing-y
  Accordion(fix)
    template(#title)
      span {{ $t('size') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    RangeInput(:label="$t('width')" v-model.number="width")
    RangeInput(:label="$t('height')" v-model.number="height")
    Margin.mb-3
  hr.sidebar-spacing-y
  BackgroundAndBorder.mt-3(
    shadow
    typeOfComponent="countdown"
    :deviceSelector="false"
    :editMobile="false"
    shadowProp="subElements.number.desktop.shadow"
  )
    template(#color)
      OmColorInput(
        :label="$t('background')"
        property="subElements.number.$device.background.color"
        typeOfComponent="countdown"
        layout="col"
        dsAllowed
      )
      OmColorInput(
        :label="$t('separatorColor')"
        property="subElements.separator.desktop.color"
        typeOfComponent="countdown"
        layout="col"
        dsAllowed
      )
    template(#border)
      Border(
        typeOfComponent="countdown"
        selectedProperty="subElements.number.desktop.border.selectedBorder"
        typeProperty="subElements.number.desktop.border.type"
        widthProperty="subElements.number.desktop.border.width"
        coloringProperty="subElements.number.desktop.border.color"
        :editMobile="false"
      )
    template(#cornerRadius)
      CornerRadius(
        radiusProperty="subElements.number.desktop.border.radius"
        chainedProperty="subElements.number.desktop.border.radiusChained"
        customThemeRoundingProperty="subElements.number.desktop.border.customThemeRounding"
      )
  hr.sidebar-spacing-y
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import moment from 'moment';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import itemMixin from '@/editor/mixins/item';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import DateTime from '@/editor/components/sidebar/components/DateTime/DateTime.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      BasePane,
      Font,
      Accordion,
      Dropdown,
      Heading,
      DeviceSelector,
      PaneLayout,
      RangeInput,
      Margin,
      Hide,
      Border,
      BackgroundAndBorder,
      DateTime,
      CornerRadius,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      flatpickrInstance: null,
    }),
    computed: {
      ...mapState(['selectedElement']),
      isFixedTime() {
        return this.getValueOf('data.countdown.type') === 'time';
      },
      isHu() {
        return this.$i18n.locale === 'hu';
      },
      countdownTypes() {
        return [
          { text: this.$t('fixedDate'), value: 'date' },
          { text: this.$t('fixedTime'), value: 'time' },
        ];
      },
      daysOptions() {
        return this.generateOptions(100);
      },
      dayValue: {
        get() {
          return this.getValueOf('data.countdown.day');
        },
        set(value) {
          this.setValueOf('data.countdown.day', value);
        },
      },
      hoursOptions() {
        return this.generateOptions(24);
      },
      hourValue: {
        get() {
          return this.getValueOf('data.countdown.hour');
        },
        set(value) {
          this.setValueOf('data.countdown.hour', value);
        },
      },
      minutesOptions() {
        return this.generateOptions(60);
      },
      minuteValue: {
        get() {
          return this.getValueOf('data.countdown.min');
        },
        set(value) {
          this.setValueOf('data.countdown.min', value);
        },
      },
      secondsOptions() {
        return this.generateOptions(60);
      },
      secondValue: {
        get() {
          return this.getValueOf('data.countdown.sec');
        },
        set(value) {
          this.setValueOf('data.countdown.sec', value);
        },
      },
      countdownType: {
        get() {
          return this.getValueOf('data.countdown.type');
        },
        set(value) {
          this.setValueOf('data.countdown.type', value);
        },
      },
      timeUnitLabel: {
        get() {
          return this.getValueOf('data.countdown.labelIsActive');
        },
        set(value) {
          this.setValueOf('data.countdown.labelIsActive', value);
        },
      },
      days: {
        get() {
          return this.getValueOf('data.countdown.labels.days');
        },
        set(value) {
          this.setValueOf('data.countdown.labels.days', value);
        },
      },
      hours: {
        get() {
          return this.getValueOf('data.countdown.labels.hours');
        },
        set(value) {
          this.setValueOf('data.countdown.labels.hours', value);
        },
      },
      minutes: {
        get() {
          return this.getValueOf('data.countdown.labels.minutes');
        },
        set(value) {
          this.setValueOf('data.countdown.labels.minutes', value);
        },
      },
      seconds: {
        get() {
          return this.getValueOf('data.countdown.labels.seconds');
        },
        set(value) {
          this.setValueOf('data.countdown.labels.seconds', value);
        },
      },
      width: {
        get() {
          return Number(this.getValueOf('subElements.number.$device.width', 100));
        },
        set(v) {
          this.setValueOf('subElements.number.$device.width', v);
        },
      },
      height: {
        get() {
          return Number(this.getValueOf('subElements.number.$device.height', 0));
        },
        set(v) {
          this.setValueOf('subElements.number.$device.height', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      fontSize: {
        get() {
          return Number(this.getValueOf('subElements.shared.$device.fontSize'));
        },
        set(v) {
          this.setValueOf('subElements.shared.$device.fontSize', v);
        },
      },
    },
    watch: {
      'selectedElement.data.countdown.type': function (v) {
        if (v === 'date') {
          this.calculateDate();
        }
      },
      'selectedElement.data.countdown.endDate.date': function () {
        this.calculateDate();
      },
    },
    methods: {
      ...mapMutations(['updateData']),
      generateOptions(count) {
        return new Array(count).fill('').map((_, index) => ({ text: index, value: index }));
      },
      calculateDate() {
        const endDate = this.selectedElement.data.countdown.endDate.date;
        const calculatedDate = moment.duration(moment(endDate) - moment(new Date()))._data;
        const calculatedDays = moment(endDate).diff(moment(new Date()), 'days');

        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.day',
          value: calculatedDays,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.hour',
          value: calculatedDate.hours,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.min',
          value: calculatedDate.minutes,
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.countdown.sec',
          value: calculatedDate.seconds,
        });
      },
    },
  };
</script>

<style lang="sass">
  .spacing
    margin-top: .75rem
  .fixed-time-container
    display: flex
    gap: 8px
</style>
